export default {
  unlimitedHotspotAccordionTitle: {
    id: 'product_page_visible_by_verizon_drawer_unlimited_hotspot_accordion_title',
    defaultMessage: 'Unlimited use of your phone as WiFi',
  },
  unlimitedHotspotAccordionBody: {
    id: 'product_page_visible_by_verizon_drawer_unlimited_hotspot_accordion_body',
    defaultMessage: `Get connected to WiFi while on the go with Visible+ hotspot, now faster than ever. Your smartphone becomes a WiFi connection for devices like tablets, laptops, and more.

      Visible+ includes mobile hotspot with unlimited data at speeds up to 10 Mbps. Video streams in SD. Limited to one connected device. After 50 GB, when the network is experiencing heavy traffic, your data may be temporarily slowed.`,
  },
}
