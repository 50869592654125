export default {
  prevSlideText: {
    id: 'buying_guide_slider_prev_slide',
    defaultMessage: 'Display previous slide',
  },
  nextSlideText: {
    id: 'buying_guide_slider_next_slide',
    defaultMessage: 'Display next slide',
  },
}
