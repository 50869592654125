<template>
  <RevList :has-external-borders="false">
    <RevAccordionItem>
      <template #prefix>
        <IconCheckInCircle />
      </template>
      <template #title>
        {{ i18n(translations.mexicoAndCanadaAccordionTitle) }}
      </template>
      <template #body>
        <p class="body-1 whitespace-pre-line">
          {{ i18n(translations.mexicoAndCanadaAccordionBody) }}
        </p>
      </template>
    </RevAccordionItem>
  </RevList>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevAccordionItem } from '@ds/components/AccordionItem'
import { RevList } from '@ds/components/List'
import { IconCheckInCircle } from '@ds/icons/IconCheckInCircle'

import translations from './InternationalPerksList.translations'

const i18n = useI18n()
</script>
