export default {
  unlimitedTalkAndTextTitle: {
    id: 'product_page_visible_by_verizon_drawer_verizon_network_list_unlimited_talk_and_text_title',
    defaultMessage: 'Unlimited talk, text, and data',
  },
  unlimitedTalkAndTextDescription: {
    id: 'product_page_visible_by_verizon_drawer_verizon_network_list_unlimited_talk_and_text_description',
    defaultMessage:
      'Get the unlimited data, messages and minutes you love without hidden fees raising your cost.',
  },
  premiumCoverageTitle: {
    id: 'product_page_visible_by_verizon_drawer_verizon_network_list_premium_coverage_title',
    defaultMessage: 'Premium coverage from Verizon’s network',
  },
  populationCoverage: {
    id: 'product_page_visible_by_verizon_drawer_verizon_network_list_population_coverage',
    defaultMessage: `Visible runs on Verizon's award-winning 5G & 4G LTE networks.

      Typical 4G LTE & 5G download speeds are 9-149 Mbps. Video streams in SD. In times of traffic, your data may be temporarily slower than other traffic.
      
      5G requires a 5G-capable device inside the 5G coverage area.`,
  },
  spamAndRobocallsBlockedTitle: {
    id: 'product_page_visible_by_verizon_drawer_verizon_network_list_span_and_robocalls_blocked_title',
    defaultMessage: 'High-risk spam and robocalls blocked.',
  },
  spamAndRobocallsBlockedContent: {
    id: 'product_page_visible_by_verizon_drawer_verizon_network_list_span_and_robocalls_blocked_content',
    defaultMessage:
      'Screens incoming calls and filters spam. Blocks high-risk spam and robocalls at our network before they even get to you.',
  },
}
