export default {
  mexicoAndCanadaAccordionTitle: {
    id: 'product_page_visible_by_verizon_drawer_mexico_and_canada_accordion_title',
    defaultMessage: 'Talk & Text in Mexico & Canada',
  },
  mexicoAndCanadaAccordionBody: {
    id: 'product_page_visible_by_verizon_drawer_mexico_and_canada_accordion_body',
    defaultMessage:
      'Calling from Canada and Mexico is unlimited for domestic calls, or internationally back to the US.',
  },
}
