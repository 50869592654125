export default {
  drawerTitle: {
    id: 'product_page_visible_by_verizon_drawer_title',
    defaultMessage: 'Exclusive savings with Visible',
  },
  title: {
    id: 'product_page_visible_by_verizon_heading',
    defaultMessage: 'Save on Verizon’s network with Visible.',
  },
  intro: {
    id: 'product_page_visible_by_verizon_drawer_intro',
    defaultMessage:
      'Only on Back Market — subscribe to the unlimited Visible+ plan for $30/month for 6 months, then $45/month. That’s a total savings of $90 on the award-winning Verizon network with Verizon perks.',
  },
  info: {
    id: 'product_page_visible_by_verizon_drawer_info',
    defaultMessage:
      'Don’t believe us? Try the 15-day Visible Free Trial with no strings attached.',
  },
  howItWorks: {
    id: 'product_page_visible_by_verizon_drawer_how_it_works',
    defaultMessage: 'How it works:',
  },
  firstListItem: {
    id: 'product_page_visible_by_verizon_drawer_how_it_works_first_list_item',
    defaultMessage: 'Buy a smartphone on Back Market',
  },
  secondListItem: {
    id: 'product_page_visible_by_verizon_drawer_how_it_works_second_list_item',
    defaultMessage:
      'Get your exclusive Visible by Verizon promo code via email.',
  },
  thirdListItem: {
    id: 'product_page_visible_by_verizon_drawer_how_it_works_third_list_item',
    defaultMessage: 'Enter the code when subscribing to the Visible+ plan.',
  },
  howItWorksEligibility: {
    id: 'product_page_visible_by_verizon_drawer_how_it_works_eligibility',
    defaultMessage:
      'This offer is only eligible for phones that are unlocked to all carriers.',
  },
  includedTitle: {
    id: 'product_page_visible_by_verizon_drawer_included_title',
    defaultMessage: 'What’s included',
  },
  verizonNetworkLink: {
    id: 'product_page_visible_by_verizon_drawer_verizon_network_link',
    defaultMessage: 'The Verizon network',
  },
  hotspotLink: {
    id: 'product_page_visible_by_verizon_drawer_hotspot_link',
    defaultMessage: 'Unlimited mobile hotspot',
  },
  internationalPerksLink: {
    id: 'product_page_visible_by_verizon_drawer_international_perks_link',
    defaultMessage: 'Free international perks',
  },
  appleWatchLink: {
    id: 'product_page_visible_by_verizon_drawer_apple_watch_link',
    defaultMessage: 'Apple Watch line included',
  },
  verizonNetworkTitle: {
    id: 'product_page_visible_by_verizon_drawer_verizon_network_title',
    defaultMessage: 'The Verizon network',
  },
  unlimitedHotspotHeading: {
    id: 'product_page_visible_by_verizon_drawer_unlimited_hotspot_heading',
    defaultMessage: 'Unlimited mobile Hotspot',
  },
  internationalPerksHeading: {
    id: 'product_page_visible_by_verizon_drawer_international_perks_heading',
    defaultMessage: 'Free international perks',
  },
  appleWatchHeading: {
    id: 'product_page_visible_by_verizon_drawer_apple_watch_heading',
    defaultMessage: 'Apple Watch',
  },
  FAQTitle: {
    id: 'product_page_visible_by_verizon_drawer_FAQ_title',
    defaultMessage: 'FAQ',
  },
}
