<template>
  <template v-if="hasArticles">
    <BlockTitle :subtitle="subtitle" :title="title" />

    <ArticleCardsCarousel :articles="formattedArticles" :tracking />
  </template>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { ArticleCardsList } from '@backmarket/http-api/src/api-specs-content/models/article-cards-list'

import type { ContentBlockProps } from '../../models/content-block'
import ArticleCardsCarousel from '../../shared-components/ArticleCard/ArticleCardsCarousel.vue'
import BlockTitle from '../../shared-components/BlockTitle/BlockTitle.vue'

import { mapToArticles } from './mapToArticles'

export type ArticleCardsListProps = ArticleCardsList & ContentBlockProps
const props = withDefaults(defineProps<ArticleCardsListProps>(), {
  title: '',
  subtitle: '',
})

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const formattedArticles = computed(() => {
  return mapToArticles(props).map((article) => {
    const { src, alt, ...imageProps } = article.image

    return {
      category: article.translatedCategory,
      id: article.id,
      imageAlt: alt,
      imageSrc: src,
      link: article.link,
      text: article.text,
      title: article.title,
      imageProps: 'width' in imageProps ? imageProps : undefined,
    }
  })
})

const hasArticles = computed(() => formattedArticles.value.length > 0)
</script>
