export default {
  howToUnlockPlanAccordionTitle: {
    id: 'product_page_visible_by_verizon_drawer_how_to_unlock_plan_accordion_title',
    defaultMessage: 'How can I unlock the exclusive Visible by Verizon plan?',
  },
  firstListItem: {
    id: 'product_page_visible_by_verizon_drawer_how_to_unlock_plan_accordion_first_list_item',
    defaultMessage: 'Buy a smartphone on Back Market.',
  },
  secondListItem: {
    id: 'product_page_visible_by_verizon_drawer_how_to_unlock_plan_accordion_second_list_item',
    defaultMessage:
      'Get your exclusive Visible by Verizon promo code via email.',
  },
  thirdListItem: {
    id: 'product_page_visible_by_verizon_drawer_how_to_unlock_plan_accordion_third_list_item',
    defaultMessage: 'Enter the code when subscribing to the Visible+ plan.',
  },
  fourthListItem: {
    id: 'product_page_visible_by_verizon_drawer_how_to_unlock_plan_accordion_fourth_list_item',
    defaultMessage: 'Get scrolling with your new coverage.',
  },
  proTip: {
    id: 'product_page_visible_by_verizon_drawer_how_to_unlock_plan_accordion_pro_tip',
    defaultMessage:
      'Pro tip: The promo code doesn’t need to be used with the phone bought on Back Market. Keep it for yourself or gift it to a friend.',
  },
  phoneLockedAccordionTitle: {
    id: 'product_page_visible_by_verizon_drawer_phone_locked_accordion_title',
    defaultMessage: 'What if my phone is locked?',
  },
  phoneLockedAccordionContent: {
    id: 'product_page_visible_by_verizon_drawer_phone_locked_accordion_content',
    defaultMessage:
      'No, the phone must be unlocked to all carriers. You\'ll know a Back Market phone is unlocked if the item\'s name says "Unlocked" at the end.  (Example: iPhone 12 64GB - Black - Unlocked).',
  },
  eSimAccordionTitle: {
    id: 'product_page_visible_by_verizon_drawer_e_sim_accordion_title',
    defaultMessage: "What if my phone doesn't have an eSIM?",
  },
  eSimAccordionContent: {
    id: 'product_page_visible_by_verizon_drawer_e_sim_accordion_content',
    defaultMessage:
      'No eSIM, no problem. Visible will send you a physical SIM kit in the mail. {phoneCompatibility, html}',
  },
  eSimAccordionLinkLabel: {
    id: 'product_page_visible_by_verizon_drawer_e_sim_accordion_link_label',
    defaultMessage: "Check your phone's compatibility",
  },
  eSimAccordionLink: {
    id: 'product_page_visible_by_verizon_drawer_e_sim_accordion_link',
    defaultMessage:
      'https://www.visible.com/shop/compatibility?intcmp=plans-grid:cta-bring:byo-compatibility',
  },
  portabilityAccordionTitle: {
    id: 'product_page_visible_by_verizon_drawer_portability_accordion_title',
    defaultMessage: 'How to transfer or port your number to Visible?',
  },
  portabilityAccordionContent: {
    id: 'product_page_visible_by_verizon_drawer_portability_accordion_content',
    defaultMessage:
      'Visible makes transferring easy. {portabilityEligibility, html}',
  },
  portabilityAccordionLinkLabel: {
    id: 'product_page_visible_by_verizon_drawer_portability_accordion_link_label',
    defaultMessage: 'Check your eligibility and learn how it works',
  },
  portabilityAccordionLink: {
    id: 'product_page_visible_by_verizon_drawer_portability_accordion_link',
    defaultMessage: 'https://www.visible.com/switch-to-visible',
  },
  getMyCodeAccordionTitle: {
    id: 'product_page_visible_by_verizon_drawer_get_my_code_accordion_title',
    defaultMessage: 'How do I get my code?',
  },
  getMyCodeAccordionContent: {
    id: 'product_page_visible_by_verizon_drawer_get_my_code_accordion_content',
    defaultMessage:
      'Once you’ve made your purchase, Back Market will send your exclusive promo-code in your confirmation email.',
  },
  contactSupportAccordionTitle: {
    id: 'product_page_visible_by_verizon_drawer_contact_support_accordion_title',
    defaultMessage: 'How to contact Visible support?',
  },
  contactSupportAccordionContent: {
    id: 'product_page_visible_by_verizon_drawer_contact_support_accordion_content',
    defaultMessage:
      'Reach out to Visible with any questions related to your exclusive phone plan.',
  },
  contactSupportAccordionLinkLabel: {
    id: 'product_page_visible_by_verizon_drawer_contact_support_accordion_link_label',
    defaultMessage: 'Contact Visible customer care',
  },
  contactSupportAccordionLink: {
    id: 'product_page_visible_by_verizon_drawer_contact_support_accordion_link',
    defaultMessage: 'https://www.visible.com/help/contact-us',
  },
}
