<template>
  <RevList :has-external-borders="false">
    <RevAccordionItem>
      <template #title>
        {{ i18n(translations.howToUnlockPlanAccordionTitle) }}
      </template>
      <template #body>
        <div class="space-y-24">
          <RevTextList class="[counter-set:list-item]" variant="ordered">
            <RevTextListItem>
              {{ i18n(translations.firstListItem) }}
            </RevTextListItem>
            <RevTextListItem>
              {{ i18n(translations.secondListItem) }}
            </RevTextListItem>
            <RevTextListItem>
              {{ i18n(translations.thirdListItem) }}
            </RevTextListItem>
            <RevTextListItem>
              {{ i18n(translations.fourthListItem) }}
            </RevTextListItem>
          </RevTextList>

          <p>{{ i18n(translations.proTip) }}</p>
        </div>
      </template>
    </RevAccordionItem>

    <RevAccordionItem>
      <template #title>
        {{ i18n(translations.phoneLockedAccordionTitle) }}
      </template>
      <template #body>
        <p class="body-1">
          {{ i18n(translations.phoneLockedAccordionContent) }}
        </p>
      </template>
    </RevAccordionItem>

    <RevAccordionItem>
      <template #title>
        {{ i18n(translations.eSimAccordionTitle) }}
      </template>
      <template #body>
        <FormattedMessage
          class="body-1"
          :definition="translations.eSimAccordionContent"
        >
          <template #phoneCompatibility>
            <RevLink target="_blank" :to="i18n(translations.eSimAccordionLink)">
              {{ i18n(translations.eSimAccordionLinkLabel) }}
            </RevLink>
          </template>
        </FormattedMessage>
      </template>
    </RevAccordionItem>

    <RevAccordionItem>
      <template #title>
        {{ i18n(translations.portabilityAccordionTitle) }}
      </template>
      <template #body>
        <FormattedMessage
          class="body-1"
          :definition="translations.portabilityAccordionContent"
        >
          <template #portabilityEligibility>
            <RevLink
              target="_blank"
              :to="i18n(translations.portabilityAccordionLink)"
            >
              {{ i18n(translations.portabilityAccordionLinkLabel) }}
            </RevLink>
          </template>
        </FormattedMessage>
      </template>
    </RevAccordionItem>

    <RevAccordionItem>
      <template #title>
        {{ i18n(translations.getMyCodeAccordionTitle) }}
      </template>
      <template #body>
        <p class="body-1">
          {{ i18n(translations.getMyCodeAccordionContent) }}
        </p>
      </template>
    </RevAccordionItem>

    <RevAccordionItem>
      <template #title>
        {{ i18n(translations.contactSupportAccordionTitle) }}
      </template>
      <template #body>
        <div class="body-1">
          <p>{{ i18n(translations.contactSupportAccordionContent) }}</p>
          <br />
          <RevLink
            target="_blank"
            :to="i18n(translations.contactSupportAccordionLink)"
          >
            {{ i18n(translations.contactSupportAccordionLinkLabel) }}
          </RevLink>
        </div>
      </template>
    </RevAccordionItem>
  </RevList>
</template>

<script setup lang="ts">
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevAccordionItem } from '@ds/components/AccordionItem'
import { RevLink } from '@ds/components/Link'
import { RevList } from '@ds/components/List'
import { RevTextList } from '@ds/components/TextList'
import { RevTextListItem } from '@ds/components/TextListItem'

import translations from './FAQList.translations'

const i18n = useI18n()
</script>
