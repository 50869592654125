<template>
  <RevIllustration
    :alt="countryCode"
    :class="sizeClass"
    :height="40"
    :src="`/img/flags/Flag${countryCode}.svg`"
    :width="28"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { Country, MarketCountryCode } from '@backmarket/http-api'
import { tw } from '@backmarket/utils/string/tw'
import { RevIllustration } from '@ds/components/Illustration'

type FlagSize = 's' | 'm' | 'l' | 'xl' | 'xxl'

export type FlagProps = {
  countryCode: `${Country}` | `${MarketCountryCode}`
  size?: FlagSize
}

const props = withDefaults(defineProps<FlagProps>(), {
  size: 'm',
})

const sizeClass = computed(() => {
  const sizeClasses: Record<FlagSize, string> = {
    s: tw`h-8 w-12`,
    m: tw`h-12 w-18`,
    l: tw`h-16 w-24`,
    xl: tw`h-20 w-[1.875rem]`,
    xxl: tw`h-12 w-18`,
  }

  return sizeClasses[props.size]
})
</script>
