import type { UnionOfPropertyTypes } from '@backmarket/utils/object/UnionOfPropertyTypes'

export const PaymentLogTypes = {
  DEPRECATION: 'PAYMENT_DEPRECATION',
  FORM_DISPLAY: 'PAYMENT_FORM_DISPLAYED',
  FORM_METHOD_SELECT: 'PAYMENT_FORM_METHOD_SELECTED',
  FORM_PREFERRED_CARD_NETWORK_SELECTED:
    'PAYMENT_FORM_PREFERRED_CARD_NETWORK_SELECTED',
  FORM_SETUP_START: 'PAYMENT_FORM_SETUP_START',
  FORM_SETUP_SUCCESS: 'PAYMENT_FORM_SETUP_SUCCESS',
  FORM_SETUP_ERROR: 'PAYMENT_FORM_SETUP_ERROR',
  FORM_SUBMIT_START: 'PAYMENT_FORM_SUBMIT_START',
  FORM_SUBMIT_SUCCESS: 'PAYMENT_FORM_SUBMIT_SUCCESS',
  FORM_SUBMIT_ERROR: 'PAYMENT_FORM_SUBMIT_ERROR',

  FORM_RESUME_START: 'PAYMENT_FORM_RESUME_START',
  FORM_RESUME_SUCCESS: 'PAYMENT_FORM_RESUME_SUCCESS',
  FORM_RESUME_ERROR: 'PAYMENT_FORM_RESUME_ERROR',

  FORM_UNHANDLED_ERROR: 'PAYMENT_FORM_UNHANDLED_ERROR',

  RESULT_SUCCESS: 'PAYMENT_RESULT_SUCCESS',
  RESULT_FAILURE: 'PAYMENT_RESULT_FAILURE',
} as const

export type PaymentLogTypes = UnionOfPropertyTypes<typeof PaymentLogTypes>
